<template>
  <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      :title="dialogTitle"
      :visible.sync="visible"
      width="80%"
      class="diaLogInput"
      center
      :before-close="handleClose"
  >
    <span>{{message}}</span>
    <div style="height: 10px"></div>
    <el-input
        placeholder="请输入内容"
        v-model="input"
        clearable
    >
    </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="sure">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'dialogInPut',
  props: {
    message:{
      type: String,
      default: ''
    },

    content: {
      type: String,
      default: ''
    },

    matchType: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: '提示'
    }
  },
  watch: {
    dialogVisible:{
      handler(value) {
        this.visible = value;
      },
      immediate: true,
      deep: true
    },
    content:{
      handler(value) {
        this.$nextTick(()=>{
          this.input = value;
        })
      },
      immediate: true,
      deep: true
    },
    matchType: {
      handler(value) {
        if (value == 'all') {
          this.match = '.'
        } else if (value == 'onlyNum') {
          this.match = '(-?[0-9]+\\.?[0-9]?)?[0-9]$'
        } else if (value == 'onlyChar') {
          this.match = '^[A-Za-z]+$'
        } else if (value == 'onlyUUID') {
          this.match = '^[A-Za-z0-9]+$'
        } else if (value == 'onlyInt') {
          this.match = '^[0-9]+$'
        } else if (value == 'onlyIP') {
          this.match = '((2(5[0-5]|[0-4]\\d))|[0-1]?\\d{1,2})(\\.((2(5[0-5]|[0-4]\\d))|[0-1]?\\d{1,2})){3}'
        } else if (value == 'onlyBool') {
          this.match = '^[0-1]$'
        }
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      input: '',
      match: '.',
      visible:false,
    }
  },
  methods: {
    errorMsg: function() {
      let msg = '╰(￣▽￣)╭'
      if (this.matchType == 'all') {
        msg = '请输入地球语言 (￣▽￣)~*'
      } else if (this.matchType == 'onlyNum') {
        msg = '请输入数字,可以包含小数点 (￣▽￣)"'
      } else if (this.matchType == 'onlyChar') {
        msg = '请输入纯字母 <(￣︶￣)>'
      } else if (this.matchType == 'onlyUUID') {
        msg = '请输入数字字母 []~(￣▽￣)~*'
      } else if (this.matchType == 'onlyInt') {
        msg = '请输入纯数字 (￣３￣)a'
      } else if (this.matchType == 'onlyIP') {
        msg = '请输入正确的IP (･ェ･。)'
      } else if (this.matchType == 'onlyBool') {
        msg = '请输入0或者1 ヾ(=･ω･=)o'
      }
      return msg
    },
    sure: function() {
      let matchTransMatrix = new RegExp(this.match, 'gmi')
      let rep = this.input.match(matchTransMatrix)
      if (rep == null) {
        this.$message({
          message: this.errorMsg(),
          type: 'warning'
        })
        return
      }
      this.$emit('dialogClose', { dialogVisible: false, result:true, input:this.input})
      // this.input = '';
    },
    close: function() {
      this.$emit('dialogClose', { dialogVisible: false, result:false})
      // this.input = '';
    },
    handleClose: function() {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>

.diaLogInput {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
}


</style>
