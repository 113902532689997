<template>
  <div class="deviceDetailView" :style="{'height':height}">
    <search-table
        :loading="loadSearch"
        height="calc(100% - 40px)"
        :table-data="searchList" @clickItem="clickItem"
    ></search-table>

    <h-stack style="height: 40px;background-color: white">
      <el-pagination
          @current-change="currentChange"
          :page-size="pageSize"
          :pager-count="5"
          :current-page="page"
          layout="prev, pager, next"
          :total="totalCount"
      >
      </el-pagination>
    </h-stack>


<!--    <el-drawer-->
<!--        :modal="false"-->
<!--        size="calc(100% - 50px)"-->
<!--        :visible.sync="showDetail"-->
<!--        :with-header="false"-->
<!--        direction="btt">-->

<!--      <search-device-detail :device-detail="selectItem"></search-device-detail>-->

<!--    </el-drawer>-->

    <el-dialog  width="90%" title="" :visible.sync="showDetail">
      <div style="width: 100%;height: 600px">
        <search-device-detail :device-detail="selectItem"></search-device-detail>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import SearchTable from '@/views/searchDevice/searchTable/searchTable'
import SearchDeviceDetail from '@/views/searchDevice/searchDeviceDetail/searchDeviceDetail'

export default {
  name: 'deviceDetailView',
  components: { SearchDeviceDetail, SearchTable, HStack },
  props: {
    status:{
      default:'',
    },
    queryInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    height: {
      type: String,
      default: () => {
        return 'calc(100%)'
      }
    }
  },
  data() {
    return {
      page: 1,
      pageSize: 50,
      totalCount: 0,
      loadSearch: false,
      searchList: [],
      showDetail: false,
      selectItem:{},
    }
  },
  methods: {
    clickItem: function(item) {
      this.showDetail = true;
      this.selectItem = JSON.parse(JSON.stringify(item))
    },
    searchDevice: function() {
      return new Promise(resolve => {
        let postInfo = {
          query: '',
          categoryId: '',
          brandId: '',
          modelId: '',
          page: this.page,
          pageSize: this.pageSize,
          deleted: 0,
          order: '',
          orderType: '',
          tenantId: '',
          isTenantIdChild: 1,
          isCategoryIdChild: 0,
          status: this.status,
          type: '',
          industryId: '',
          checkStatus: ''
        }

        for (const key in this.queryInfo) {
          postInfo[key] = this.queryInfo[key]
        }

        this.$api.assetModule.getSearchDeviceList(postInfo).then(res => {
          let list = []
          if (typeof res.data != 'undefined' && res.data != null) {
            list = res.data
          }
          let totalCount = 0
          if (typeof res.totalCount != 'undefined' && res.totalCount != null) {
            totalCount = res.totalCount
          }
          resolve({ list: list, totalCount: totalCount })
        }).catch(e => {
          resolve({ list: [], totalCount: 0 })
        })
      })
    },
    reset: function() {
      this.page = 1
    },
    startSearch: function() {
      this.loadSearch = true
      this.searchDevice().then(res => {
        this.loadSearch = false
        this.searchList = res.list
        this.totalCount = res.totalCount
      })
    },
    currentChange: function(index) {
      this.page = index
      this.startSearch()
    }
  }
}
</script>

<style lang="scss" scoped>

.deviceDetailView {

}


::v-deep .el-dialog {
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 200px);
  max-width:calc(100% - 30px);
}
::v-deep .el-dialog .el-dialog__body {
  flex:1;
  overflow: auto;
}

</style>
