<template>
  <div class="unbindDetailCollapse">
    <device-detail-view :status="6" :query-info="queryInfo"  ref="unBind" :height="`calc(100vh - ${(navigationBatHeight + 40 + 48 * 2  + (queryInfo.query == '' ? 0 : 20))}px)`"></device-detail-view>
  </div>
</template>

<script>
import DeviceDetailView from '@/views/breatheManager/deviceDetailView'
export default {
  name: 'unbindDetailCollapse',
  components: { DeviceDetailView },
  props:{
    queryInfo:{
      type:Object,
      default:() => {
        return {}
      }
    },
  },
  computed: {
    navigationBatHeight() {
      let type = 'web'
      if (typeof localStorage['weChatType'] != 'undefined') {
        type = localStorage['weChatType']
      }
      if (type == 'weChat') {
        return 0
      }
      return 49
    }
  },
  methods:{
    searchDevice:function() {
      this.$refs['unBind'].startSearch();
    },
  },
}
</script>

<style lang="scss" scoped>

.unbindDetailCollapse {
  width: 100%;

}

</style>
