<template>
<div class="searchView">
  <div>
    <div class="title">资产名称</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'name') == false ? '-' : dataInfo['name'] }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">所属科室</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'tenantName') == false ? '-' : dataInfo['tenantName']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">{{$VUEAPPDEVICECODE()}}</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'code') == false ? '-' : dataInfo['code']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">资产品牌</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'brandName') == false ? '-' : dataInfo['brandName']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">资产类型</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'categoryName') == false ? '-' : dataInfo['categoryName']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">资产型号</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'modelName') == false ? '-' : dataInfo['modelName']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">标签</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'label') == false ? '-' : dataInfo['label']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">资产位置</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'place') == false ? '-' : dataInfo['place']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">联系人</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'contact') == false ? '-' : dataInfo['contact']  }}</div>
    <div style="clear: both"></div>
  </div>
  <div>
    <div class="title">联系电话</div>
    <div class="content">{{ $valueIsExist(dataInfo, 'phone') == false ? '-' : dataInfo['phone']  }}</div>
    <div style="clear: both"></div>
  </div>
<!--  <div>-->
<!--    <div class="title">更新时间</div>-->
<!--    <div class="content">{{ $valueIsExist(dataInfo, 'updateTime') == false ? '-' : formatDate(dataInfo.updateTime)  }}</div>-->
<!--    <div style="clear: both"></div>-->
<!--  </div>-->

</div>
</template>

<script>
export default {
  name: 'searchView',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.searchView {

  .title {
    width: 60px;
    text-align: right;
    height: 30px;
    line-height: 25px;
    font-size: 13px;
    float: left;
  }

  .content {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 80px);
    text-align: right;
    line-height: 25px;
    font-size: 13px;
    text-align: left;
    float: right;
    word-break: break-all;
  }


}

</style>
