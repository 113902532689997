<template>
  <el-collapse class="bindDetailCollapse" v-model="bind" @change="handleChange" accordion>
    <el-collapse-item v-show="(bind == '' || bind == 'inLine')" class="manager" :title="`未开机(可调配) ${countInfo.close} 台`" name="inLine">
      <device-detail-view :status="0" :query-info="queryInfo" ref="inLine" :height="`calc(100vh - ${(navigationBatHeight + 40 + 48* 2 + (queryInfo.query == '' ? 0 : 20))}px)`"
      ></device-detail-view>
    </el-collapse-item>

    <el-collapse-item v-show="(bind == '' || bind == 'open')" class="manager" :title="`开机中 ${countInfo.open} 台`" name="open">
      <device-detail-view :status="1" :query-info="queryInfo"  ref="open" :height="`calc(100vh - ${(navigationBatHeight + 40 + 48 * 2 + (queryInfo.query == '' ? 0 : 20))}px)`"
      ></device-detail-view>
    </el-collapse-item>

    <el-collapse-item v-show="(bind == '' || bind == 'outLine')" class="manager" :title="`离线中 ${countInfo.outLine} 台`" name="outLine">
      <device-detail-view :status="4" :query-info="queryInfo"  ref="outLine" :height="`calc(100vh - ${(navigationBatHeight + 40 + 48 * 2 + (queryInfo.query == '' ? 0 : 20))}px)`"
      ></device-detail-view>
    </el-collapse-item>

    <el-collapse-item v-show="(bind == '' || bind == 'bad')" class="manager" :title="`报障中 ${countInfo.bad} 台`" name="bad">
      <device-detail-view :status="3" :query-info="queryInfo"  ref="bad" :height="`calc(100vh - ${(navigationBatHeight + 40 + 48 * 2 + (queryInfo.query == '' ? 0 : 20))}px)`"
      ></device-detail-view>
    </el-collapse-item>

  </el-collapse>
</template>

<script>
import DeviceDetailView from '@/views/breatheManager/deviceDetailView'

export default {
  name: 'bindDetailCollapse',
  components: { DeviceDetailView },
  props:{
    queryInfo:{
      type:Object,
      default:() => {
        return {}
      }
    },
    countInfo:{
      type:Object,
      default:() => {
        return {
          outLine: 0,
          close: 0,
          open: 0,
          bad:0,
        }
      }
    }
  },
  computed: {
    navigationBatHeight() {
      let type = 'web'
      if (typeof localStorage['weChatType'] != 'undefined') {
        type = localStorage['weChatType']
      }
      if (type == 'weChat') {
        return 0
      }
      return 49
    }
  },
  data() {
    return {
      bind: ''
    }
  },
  methods: {
    handleChange(val) {
      this.$emit('changeCollapse', { value: val, type: val == '' ? '' : 'bind' })
      if (val == '') {
        return;
      }
      if (this.$valueIsExist(this.$refs, val) == false) {
        return;
      }
      this.$refs[val].startSearch();
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}

.manager {
  padding-left: 20px;
}

</style>
