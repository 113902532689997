<!--<template>-->
<!--  <el-table-->
<!--      v-loading="loading"-->
<!--      border-->
<!--      stripe-->
<!--      width="100%"-->
<!--      :height="height"-->
<!--      :data="tableData"-->
<!--      class="checkAssetList" @row-click="rowClick"-->
<!--  >-->
<!--    <el-table-column-->
<!--        fixed-->
<!--        header-align="center"-->
<!--        type="index"-->
<!--        :index="indexMethod"-->
<!--        label="序号"-->
<!--        width="50">-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        fixed-->
<!--        prop="name"-->
<!--        label="资产名称"-->
<!--        :width="'140px'"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="code"-->
<!--        :label={{$VUEAPPDEVICECODE()}}-->
<!--        :width="'120px'"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="place"-->
<!--        label="资产位置"-->
<!--        width="160px"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="brandName"-->
<!--        label="资产品牌"-->
<!--        width="150px"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="categoryName"-->
<!--        label="资产类型"-->
<!--        width="140px"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="modelName"-->
<!--        label="资产型号"-->
<!--        width="150px"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="tenantName"-->
<!--        label="科室"-->
<!--        :width="width"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="label"-->
<!--        label="标签"-->
<!--        width="120px"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="updateTime"-->
<!--        label="更新时间"-->
<!--        width="180px"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ formatDate(scope.row.updateTime) }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->



<!--  </el-table>-->
<!--</template>-->

<template>
  <el-table
      :show-header="false"
      v-loading="loading"
      border
      stripe
      width="100%"
      :height="height"
      :data="tableData"
      class="checkAssetList" @row-click="rowClick">
    <el-table-column>
      <template slot-scope="scope">
        <search-view :data-info="scope.row"></search-view>
      </template>
    </el-table-column>

  </el-table>
</template>


<script>
import SearchView from '@/views/searchDevice/searchTable/searchView'
export default {
  name: 'searchTable',
  components: { SearchView },
  props: {
    height: {
      type: String,
      default: 'calc(100% - 250px)'
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: 'calc(100%/8) <= 120px ? 120px : calc(100%/8)'
    }
  },
  methods: {
    indexMethod: function (index) {
      return index + 1;
    },
    rowClick: function(item) {
      this.$emit('clickItem', item);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
