<template>
  <div class="breatheManager" id="breatheManager">
    <div style="display: flex;align-items: center;justify-content: space-between">
      <div class="contentTitle">全院{{ title }}共计 <span style="color:#0279E1;">{{ countInfo.totalCount }}</span> 台
      </div>
      <i @click="queryVisible=true" style="margin-right: 10px" class="el-icon-search"></i>
    </div>

    <div v-if="queryInfo.query != ''"
         style="display: flex;align-items: center;justify-content: space-between;height: 20px"
    >
      <div class="searchRemind"><span style="color:#0279E1;">{{ queryInfo.query }}</span>搜索结果</div>
      <i @click="clearQuery" style="margin-right: 10px" class="el-icon-circle-close"></i>
    </div>


    <el-collapse class="manager" v-model="bindDevice" @change="handleChange" accordion>
      <el-collapse-item v-show="showBind" :title="`已安装物联网标签 ${countInfo.bind} 台`"
                        name="bind"
      >
        <bind-detail-collapse :query-info="queryInfo" :count-info="countInfo" @changeCollapse="changeCollapse"
        ></bind-detail-collapse>
      </el-collapse-item>

      <el-collapse-item v-show="showUnbind" :title="`未安装物联网标签 ${countInfo.unBind} 台`"
                        name="unBind"
      >
        <unbind-detail-collapse :query-info="queryInfo" ref="unBind"></unbind-detail-collapse>
      </el-collapse-item>

    </el-collapse>

    <dialog-in-put @dialogClose="dialogClose"
                   :content="''"
                   :message="'设备搜索'"
                   :match-type="'all'"
                   :dialog-visible="queryVisible"
    >
    </dialog-in-put>

  </div>


</template>

<script>

import BindDetailCollapse from '@/views/breatheManager/bindDetailCollapse'
import UnbindDetailCollapse from '@/views/breatheManager/unbindDetailCollapse'
import { Loading } from 'element-ui'
import DialogInPut from '@/components/inPut/dialogInPut'

export default {
  name: 'breatheManager',
  components: { DialogInPut, UnbindDetailCollapse, BindDetailCollapse },
  computed: {
    showBind() {
      if (this.bindDevice == '') {
        return true
      }
      if (this.selectType == '') {
        return true
      }
      if (this.selectType == 'bind') {
        return true
      }
      return false
    },
    showUnbind() {
      if (this.bindDevice == '') {
        return true
      }
      if (this.selectType == '') {
        return true
      }
      if (this.selectType == 'unBind') {
        return true
      }
      return false
    }
  },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      breatheIds: 'breatheIds',
      title: '呼吸机',
      queryWord: '呼吸',
      queryVisible: false,
      loading: false,
      loadingView: null,
      showDetail: false,
      totalCount: 0,
      bindDevice: '',
      selectType: '',
      countInfo: {
        totalCount: 0,
        outLine: 0,
        close: 0,
        open: 0,
        bad: 0,
        unBind: 0,
        bind: 0
      },
      queryInfo: {
        categoryId: '',
        query: ''
      }
    }
  },
  mounted() {
    let query = this.$route.query
    if (this.$valueIsExist(query, 'title')) {
      this.title = query['title']
    }
    if (this.$valueIsExist(query, 'queryWord')) {
      this.queryWord = query['queryWord']
    }
    if (this.$valueIsExist(query, 'breatheIds')) {
      this.breatheIds = query['breatheIds']
    }

    this.$nextTick(() => {
      this.getAllData()
    })
  },
  methods: {
    clearQuery: function() {
      this.queryInfo.query = ''
      this.getAllData()
    },
    dialogClose: function(res) {
      this.queryInfo.query = res.input
      this.queryVisible = false
      this.getAllData()
    },
    startLoading: function() {
      let dom = document.getElementById('breatheManager')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    changeCollapse: function(res) {
      this.selectType = res.type
    },
    handleChange(val) {
      if (val == '' || val == 'unBind') {
        this.selectType = ''
        if (val == 'unBind') {
          this.$refs['unBind'].searchDevice()
        }
      }
    },
    getAllData() {
      let breatheIds = ''
      if (this.$valueIsExist(localStorage, this.breatheIds)) {
        breatheIds = localStorage[this.breatheIds]
      }

      let findId = (list, ids) => {
        if (list.length == 0) {
          return ids
        }
        let children = []
        let res = ''
        console.log(this.queryWord)
        for (let i = 0; i < list.length; i++) {
          let info = list[i]
          if (info.name.indexOf(this.queryWord) >= 0) {
            res = res + (res == '' ? '' : ',') + info.id
          }

          if (this.$valueIsExist(info, 'children') == false) {
            continue
          }
          children = children.concat(info['children'])
        }

        let resId = ids + (ids == '' ? '' : ',') + res
        return findId(children, resId)
      }

      let getAssetCategory = () => {
        return new Promise(resolve => {
          this.assetCategoryList = []
          this.$api.assetModule.getDeviceCategoryTree({}).then(res => {
            let categoryId = findId(res.data, '')
            resolve({ categoryId: categoryId })
          }).catch(e => {
            resolve({ categoryId: '' })
          })
        })
      }

      let getData = (status) => {
        return new Promise(resolve => {
          let postInfo = {
            deleted: 0,
            query: this.queryInfo.query,
            page: 1,
            pageSize: 30,
            order: '',
            orderType: '',
            tenantId: '',
            isTenantIdChild: 1,
            categoryId: this.queryInfo.categoryId,
            isCategoryIdChild: 0,
            status: status,
            type: '',
            industryId: '',
            checkStatus: '',
            deviceInventoryStatus: ''
          }

          this.$api.assetModule.getSearchDeviceList(postInfo).then(res => {
            let totalCount = 0
            if (typeof res.totalCount != 'undefined' && res.totalCount != null) {
              totalCount = res.totalCount
            }
            resolve({ totalCount: totalCount })
          }).catch(e => {
            resolve({ totalCount: 0 })
          })
        })
      }

      let getCount = () => {
        // 0 关机 1 开机 3报障 4 未见 6 未绑定 7绑定
        let list = [getData(0), getData(1), getData(4), getData(6), getData(7), getData(3)]
        Promise.all(list).then(res => {
          this.countInfo.close = res[0].totalCount
          this.countInfo.open = res[1].totalCount
          this.countInfo.outLine = res[2].totalCount
          this.countInfo.unBind = res[3].totalCount
          this.countInfo.bad = res[5].totalCount
          this.countInfo.bind = this.countInfo.bad + this.countInfo.close + this.countInfo.open + this.countInfo.outLine
          this.countInfo.totalCount = this.countInfo.bind + this.countInfo.unBind
          this.loading = false
        })
      }

      this.loading = true
      if (breatheIds == '') {
        getAssetCategory().then(result => {
          this.queryInfo.categoryId = result.categoryId
          getCount()
        })
      } else {
        this.queryInfo.categoryId = breatheIds
        getCount()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.breatheManager {
  width: 100%;
  height: 100%;

  .contentTitle {
    margin-top: 5px;
    width: calc(100% - 40px);
    height: 30px;
    line-height: 30px;
    padding: 0px 10px 0px 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    font-style: italic;
  }

  .searchRemind {
    width: calc(100% - 40px);
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    color: #9fa0a0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px 10px 0px 10px;
  }

  .manager {
    padding-left: 20px;
    background-color: white;
  }

}

::v-deep .el-collapse-item__content {
  padding-bottom: 0px;
}

</style>
